import moment from 'moment'

import { humanizedDateFromEpoch } from './formatters'

export const isSameDay = (one, two) => moment(one).isSame(two, 'day')

export const isToday = date => isSameDay(new Date(date), new Date())

export const isYesterday = date => {
  const yesterday = moment(new Date()).subtract(1, 'day')
  return isSameDay(date, yesterday)
}

export const isBeforeDay = (a, b) => moment(a).isBefore(b, 'day')
export const isBeforeToday = date => isBeforeDay(date, moment())

export const DATE_FORMAT = 'YYYY-MM-DD'

export const formatMomentDate = (date, format = DATE_FORMAT) => {
  if (date) {
    // if already a moment, just call format
    if (moment.isMoment(date)) {
      return date.format(format)
    }

    // we may receive dates in the following formats:
    // '2020-05-01', 1596002670579 or '1596002670579'
    // so this part it's just to make sure that we are receiving,
    // parsing and formatting the data properly, regardless of the date type.
    let newDate = moment(date)

    if (!newDate.isValid()) {
      newDate = moment(parseInt(date, 10))
    }

    return newDate.format(format)
  }
  return ''
}

export const humanizeDate = date =>
  humanizedDateFromEpoch(new Date(date).getTime())

export const getPreviousDateRange = (startDate, endDate) => {
  let previousStartDate
  let previousEndDate
  const isFullMonthSelected =
    startDate.isSame(endDate, 'month') &&
    startDate
      .clone()
      .startOf('month')
      .date() === startDate.date() &&
    endDate
      .clone()
      .endOf('month')
      .date() === endDate.date()

  if (isFullMonthSelected) {
    previousStartDate = startDate
      .clone()
      .subtract(1, 'month')
      .startOf('month')
    previousEndDate = endDate
      .clone()
      .subtract(1, 'month')
      .endOf('month')
  } else {
    const intervalDays = endDate.diff(startDate, 'days') + 1
    previousStartDate = startDate.clone().subtract(intervalDays, 'days')
    previousEndDate = endDate.clone().subtract(intervalDays, 'days')
  }

  return { previousStartDate, previousEndDate }
}

export const daysBeforeDate = (date, num) => {
  if (date == null || num == null) return null

  const newDate = new Date(date)
  newDate.setDate(newDate.getDate() - num)

  return newDate
}

export const getStartOfToday = () => {
  const date = new Date()
  return date.setHours(0, 0, 0, 0)
}

export const getNYearsAgo = (numYears = 1) => {
  const date = new Date(getStartOfToday())
  return date.setFullYear(date.getFullYear() - numYears)
}

export const epochToUtcDate = epoch => {
  const d = new Date(0)
  d.setUTCSeconds(epoch / 1000)
  return d.toISOString().split('T')[0]
}

export const addDaysToEpoch = (date, days) => {
  return new Date(0).setUTCSeconds(date / 1000 + days * 24 * 60 * 60)
}

export const addMonthsToDate = (date, months) => {
  return date.setMonth(date.getMonth() + months)
}

export const addYearsToDate = (date, years) => {
  return date.setYear(date.getFullYear() + years)
}

export const getEarliestDate = dates => {
  if (!dates) return null

  return dates.reduce((earliest, date) => {
    if (!date) return earliest

    if (!earliest || moment(date) < moment(earliest)) {
      return date
    }

    return earliest
  }, null)
}

export const getLatestDate = dates => {
  if (!dates) return null

  return dates.reduce((latest, date) => {
    if (!date) return latest

    if (!latest || moment(date) > moment(latest)) {
      return date
    }

    return latest
  }, null)
}

export const toUTC = date =>
  new Date(
    moment(date)
      .utc()
      .format('MM/DD/YYYY')
  )

export const isAfterRefLineDate = (dateString, refLineDate) => {
  const convertedDateString = moment(dateString)
  const mayDate = moment(refLineDate)
  return convertedDateString.isSameOrAfter(mayDate)
}

export const getLastDayOfMonth = (date, dateFormat = 'MMMM DD, YYYY') => {
  if (!date) {
    return ''
  }

  return moment(date)
    .endOf('month')
    .format(dateFormat)
}

export const getFirstDayOfMonth = (date, dateFormat = 'MMMM DD, YYYY') => {
  if (!date) {
    return ''
  }

  return moment(date)
    .startOf('month')
    .format(dateFormat)
}

export const numberOfDaysXMonthsFromNow = months => {
  return moment().diff(moment().subtract(months, 'months'), 'days')
}
